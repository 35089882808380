import { GoogleMap } from '@react-google-maps/api';
import React, { Fragment, ReactElement, useState } from 'react';

import Header from '../components/Header';
import Footprint from '../components/SunTransit/Footprint';
import InnerCone from '../components/SunTransit/InnerCone';
import OuterCone from '../components/SunTransit/OuterCone';
import ShadowTracks from '../components/SunTransit/ShadowTracks';
import StationMarker from '../components/SunTransit/StationMarker';
import SunTransitForm from '../components/SunTransit/SunTransitForm';
import TransitDataSummary from '../components/SunTransit/TransitDataSummary';
import { AppConfig } from '../config/app.config';
import { Satellite } from '../data/models/Satellite';
import { Station } from '../data/models/Station';

export interface SunTransitState {
  transitTable;
  satellite: Satellite;
  station: Station;
}

export function SunTransitCalculatorPage(): ReactElement {
  const [state, setState] = useState<SunTransitState>();

  return (
    <div className="lg:flex lg:min-h-screen lg:flex-col">
      <Header />
      <div className="lg:grid-cols-3 grid items-stretch flex-grow bg-white">
        <SunTransitForm setState={setState} />
        <TransitDataSummary satellite={state?.satellite} station={state?.station} transitTable={state?.transitTable} />
        <div className="lg:min-h-full h-96">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              styles: AppConfig.googleMaps.config.mapStyle as google.maps.MapTypeStyle[],
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              minZoom: 2,
              restriction: {
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 180,
                },
              },
            }}
            zoom={2}
            center={{ lat: 0, lng: 0 }}
          >
            {state?.transitTable && (
              <Fragment>
                <ShadowTracks transitTable={state?.transitTable} satelliteLng={state?.satellite?.lng} />
                <InnerCone transitTable={state?.transitTable} satellite={state?.satellite} station={state?.station} />
                <OuterCone satellite={state?.satellite} station={state?.station} />
                <Footprint satellite={state?.satellite} />
                <StationMarker station={state?.station} />
              </Fragment>
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}

export default SunTransitCalculatorPage;
