import React, { ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import { Satellite } from '../../data/models/Satellite';

interface Props {
  satellite: Satellite;
}

export default function Downloads({ satellite }: Props): ReactElement {
  const filename = satellite?.name.replace(/\s/g, '').replace(/Vantage/g, 'V');

  return (
    <div className="bg-primary-50 border-primary-500 border-1 p-3 space-y-1 text-sm">
      {satellite.name}
      <a className="block" download href={`${AppConfig.data.shadowPlot}/${filename.toLowerCase()}.png`}>
        Download satellite shadow plot (.png)
      </a>
      <a className="block" download href={`${AppConfig.data.root}/${filename}.ST.csv`}>
        Download satellite shadow plot (.csv)
      </a>
    </div>
  );
}
