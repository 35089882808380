import { SatelliteData } from '../data/data';
import { Satellites } from '../data/models/Satellite';

export default function FleetLongitudesData(csv: string): Satellites {
  const values = csv.trim().split(/,|\n/);
  const fleet = { ...SatelliteData };
  // const fleet = {};

  for (let i = 1; i < values.length; i += 2) {
    const key = fixKey(values[i]);
    let lng = parseFloat(values[i + 1]);

    if (lng > 180) {
      lng = lng - 360;
    }

    fleet[key] = {
      ...SatelliteData[key],
      lng: lng * -1,
    };
  }

  return fleet;
}

function fixKey(key: string): string {
  switch (key) {
    case 'AnikF1R': {
      return 'anik_f1r';
    }
    case 'AnikF1': {
      return 'anik_f1';
    }
    case 'AnikF2': {
      return 'anik_f2';
    }
    case 'AnikF3': {
      return 'anik_f3';
    }
    case 'AnikG1': {
      return 'anik_g1';
    }
    case 'NimiqN4': {
      return 'nimiq_4';
    }
    case 'NimiqN5': {
      return 'nimiq_5';
    }
    case 'NimiqN6': {
      return 'nimiq_6';
    }
    case 'Telstar11N': {
      return 't11n';
    }
    case 'Telstar12': {
      return 't12';
    }
    case 'Telstar12V': {
      return 't12v';
    }
    case 'Telstar14R': {
      return 't14r';
    }
    case 'Telstar18V': {
      return 't18v';
    }
    case 'Telstar19V': {
      return 't19v';
    }
  }
}
