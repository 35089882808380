import { Marker, useGoogleMap } from '@react-google-maps/api';
import React, { createContext, Fragment, useCallback, useEffect, useState } from 'react';

import { AppConfig } from '../config/app.config';
import clearMap from '../utils/clear-map';
import loadBeams from '../utils/load-beams';
import loadElevationCircles from '../utils/load-elevation-circles';
import processPoints from '../utils/process-points';
import resetMap from '../utils/reset-map';

type MapContext = {
  map: google.maps.Map<Element> | null;
  satelliteId: string;
  setSatelliteId: React.Dispatch<React.SetStateAction<string>>;
  beamId: string;
  setBeamId: React.Dispatch<React.SetStateAction<string>>;
  features: google.maps.Data.Feature[];
};

export const MapContext = createContext<Partial<MapContext>>({});

export const MapContextProvider: React.FC = ({ children }) => {
  const map = useGoogleMap();

  const [satelliteId, setSatelliteId] = useState<string>();
  const [beamId, setBeamId] = useState<string>();
  const [features, setFeatures] = useState<google.maps.Data.Feature[]>([]);
  const [beamMarkers, setBeamMarkers] = useState([]);
  const [elevationMarkers, setElevationMarkers] = useState([]);

  map.data.setStyle({ fillOpacity: 0, strokeOpacity: 0 });

  const onLoadBeam = useCallback(
    (feats: google.maps.Data.Feature[]) => {
      // Determined the bounds of the new features...
      const bounds = new google.maps.LatLngBounds();

      for (const feature of feats) {
        processPoints(feature.getGeometry(), bounds.extend, bounds);
        map.data.overrideStyle(feature, {
          ...AppConfig.googleMaps.config.beamContour,
          icon: { path: google.maps.SymbolPath.CIRCLE, strokeColor: 'transparent' },
        });
      }
      map.fitBounds(bounds, { bottom: 0, top: 0, right: window.innerWidth / 5, left: 0 });

      setBeamMarkers(featuresToMarkers(feats));
      setFeatures(oldFeats => {
        oldFeats.forEach(feature => map.data.remove(feature));
        return feats;
      });
    },
    [map],
  );

  /**
   * When satellite changes...
   * 1. Remove all features on the map
   * 2. Load the satellite's elevation circles
   */
  useEffect(() => {
    setBeamMarkers([]);

    if (satelliteId) {
      setElevationMarkers([]);
      clearMap(map);
      loadElevationCircles(satelliteId, map).then(markers => setElevationMarkers(markers));
    } else {
      setElevationMarkers([]);
      resetMap(map);
    }
  }, [satelliteId, map]);

  /**
   * When beam changes...
   */
  useEffect(() => {
    if (satelliteId) {
      loadBeams(satelliteId, beamId, map).then(onLoadBeam);
    }
  }, [satelliteId, beamId, map, onLoadBeam]);

  const mapContext: MapContext = {
    map,
    satelliteId,
    setSatelliteId,
    beamId,
    setBeamId,
    features,
  };

  return (
    <MapContext.Provider value={mapContext}>
      <Fragment>
        {elevationMarkers.map((marker, idx) => (
          <Marker
            key={idx}
            position={marker.position}
            label={{ ...AppConfig.googleMaps.config.beamMarker.label, text: `${marker.label}°` }}
            icon={{ ...AppConfig.googleMaps.config.beamMarker.icon, path: google.maps.SymbolPath.CIRCLE, scale: 15 }}
          />
        ))}
        {beamMarkers.map((marker, idx) => (
          <Marker
            key={idx}
            position={marker.position}
            label={{ ...AppConfig.googleMaps.config.beamMarker.label, text: marker.text }}
            icon={AppConfig.googleMaps.config.beamMarker.icon}
          />
        ))}
        {children}
      </Fragment>
    </MapContext.Provider>
  );
};

function featuresToMarkers(features: google.maps.Data.Feature[]): { position: google.maps.LatLng; text: string }[] {
  return features.flatMap(feature => {
    const text = feature.getProperty('name').split(' ')[0];
    const geometry = feature.getGeometry();

    if (geometry instanceof google.maps.Data.GeometryCollection) {
      return geometry
        .getArray()
        .filter(geo => geo.getType() === 'Point')
        .map(point => {
          return {
            position: (point as google.maps.Data.Point).get(),
            text,
          };
        });
    }

    if (geometry instanceof google.maps.Data.Point) {
      return { position: geometry.get(), text };
    }

    return [];
  });
}

export const { Consumer } = MapContext;
