import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import { SatelliteData } from '../../data/data';
import ValidationError from './ValidationError';

export default function SatelliteSelect(): ReactElement {
  const { register, errors } = useFormContext();

  return (
    <div>
      <label className="label block" htmlFor="satellite">
        Select a satellite
      </label>
      <select className="form-select w-full" name="satellite" ref={register} placeholder="Select a satellite">
        <option>Select a satellite</option>
        {Object.values(SatelliteData).map(sat => (
          <option key={sat.slug} value={sat.slug}>
            {sat.name}
          </option>
        ))}
      </select>
      <ValidationError error={errors?.satellite} />
    </div>
  );
}
