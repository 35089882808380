/**
 * Convert degrees to radians
 * @param {number} degrees Degrees
 * @returns {number} Radians
 */
function rad(degrees: number): number {
  return (degrees * Math.PI) / 180.0;
}

export default rad;
