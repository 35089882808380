function processPoints(
  geometry: google.maps.Data.Geometry | any,
  callback: (point: google.maps.LatLng | google.maps.LatLngLiteral) => google.maps.LatLngBounds,
  thisArg: google.maps.LatLngBounds,
): void {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function (g) {
      processPoints(g, callback, thisArg);
    });
  }
}

export default processPoints;
