export enum TeleportType {
  FirstParty,
  ThirdParty,
}

export interface Teleport {
  label: string;
  lat: number;
  lng: number;
  type?: TeleportType;
}
