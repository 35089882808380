import React, { ReactElement } from 'react';

import CenterOfBox from '../components/CenterOfBox/CenterOfBox';
import Header from '../components/Header';

export default function CenterOfBoxPage(): ReactElement {
  return (
    <div className="lg:flex lg:h-screen lg:flex-col">
      <Header />
      <div className="lg:h-0 lg:grid-cols-2 max-w-7xl grid items-stretch flex-grow w-full mx-auto bg-white">
        <div className="p-6 space-y-6">
          <h1 className="text-2xl font-medium leading-6 text-gray-900">Centre of Box Information</h1>
          <p className="text-xs">
            <span className="font-bold">Note:</span> Centre of Box predicts are computed daily at 0:00 local time.
            Predicts are generated for a period from 3 days before the predict generation time to 5 days after, based on
            the current best orbit estimate . Because satellite maneuvers have a profound effect on Centre of Box
            predicts and are normally planned a day before execution, predicts more than a day after the predict
            execution time are subject to change .
          </p>

          <h2 className="text-lg">What is centre of box?</h2>
          <p className="text-sm">
            Centre of Box predicts are used to point ground antennas at the satellites in orbit. Because the
            satellite&rsquo;s orbit is never perfect, the latitude and longitude vary with time. By peaking non-tracking
            earth station antennas to the Centre of Box, antenna gain variations are minimized.
          </p>
          <p className="text-sm">
            Each day, there are two points where the satellite comes closest to the centre of the station keeping box.
            These points are given in the Centre of Box predict table that pops up when you click on a satellite. In the
            table, the Latitude Deviation is the deviation from the nominal station keeping longitude, and the Longitude
            Deviation is the deviation from the nominal station keeping longitude. The smaller the deviation values, the
            closer the satellite is to the Centre of Box.
          </p>
          <p className="text-sm">
            Not all Centre of Box approaches are equally close. When peaking large antennas it is advisable to look for
            a Centre of Box approach with minimal deviation.
          </p>
        </div>
        <CenterOfBox />
      </div>
    </div>
  );
}
