import React, { ReactElement } from 'react';

interface Props {
  transitTable;
}

export default function TransitTableEng({ transitTable }: Props): ReactElement {
  return (
    <div className="">
      <table>
        <thead>
          <tr>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Date</span>
                <span>Year/Mn/Dy</span>
              </div>
            </th>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Peak</span>
                <span>Transit</span>
                <span>Hr:Mn:Sc</span>
              </div>
            </th>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Transit</span>
                <span>Start</span>
                <span>Hr:Mn:Sc</span>
              </div>
            </th>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Transit</span>
                <span>End</span>
                <span>Hr:Mn:Sc</span>
              </div>
            </th>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Transit</span>
                <span>Duration</span>
                <span>(min)</span>
              </div>
            </th>
            <th>
              <div className="flex flex-col justify-between h-full">
                <span>Max C/N</span>
                <span>Degrad.</span>
                <span>(db)</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {transitTable?.map((item, idx) => {
            if (typeof item['cnr'] !== 'undefined') {
              return (
                <tr key={idx}>
                  <td>{item[0]['T1'].toDate()}</td>
                  <td>{item['cnr'].peak.toTod()}</td>
                  <td>{item['cnr'].start.toTod()}</td>
                  <td>{item['cnr'].end.toTod()}</td>
                  <td>{item['cnr'].duration.toFixed(1)}</td>
                  <td>{item['cnrCrit'].toFixed(2)}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
