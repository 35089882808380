import React, { ReactElement } from 'react';
import { FieldError } from 'react-hook-form/dist/types/form';

interface Props {
  error: FieldError;
}

export function ValidationError({ error }: Props): ReactElement {
  return <span className="text-xs text-red-700">{error?.message}</span>;
}

export default ValidationError;
