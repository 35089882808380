import { AppConfig } from '../config/app.config';

export function loadElevationCircles(
  satelliteId: string,
  map: google.maps.Map,
): Promise<{ position: google.maps.LatLng; label: string }[]> {
  return new Promise(resolve => {
    map.data.loadGeoJson(`/static/satellites/${satelliteId}/elevation_circles.json`, undefined, feats => {
      const markers = [];

      for (const feature of feats) {
        const label = (feature.getProperty('name') as string)?.match(/\d+/g)[0];

        let position: google.maps.LatLng;
        feature.getGeometry().forEachLatLng(latLng => {
          position = latLng;
        });

        markers.push({ label, position });

        map.data.overrideStyle(feature, {
          strokeColor: AppConfig.googleMaps.config.elevationCircles.strokeColor,
          strokeWeight: AppConfig.googleMaps.config.elevationCircles.strokeWeight,
          strokeOpacity: 1,
        });
      }

      resolve(markers);
    });
  });
}

export default loadElevationCircles;
