import React, { Fragment, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import SatelliteInfoCard from '../components/Fleet/SatelliteInfoCard';
import { MapContext } from '../contexts/MapContext';
import { SatelliteData } from '../data/data';
import { Satellite } from '../data/models/Satellite';

const SatellitePage: React.FC = () => {
  const { params } = useRouteMatch<{ satelliteId: string; beamId: string }>();
  const { satelliteId, setSatelliteId, beamId, setBeamId } = useContext(MapContext);

  const satellite: Satellite = SatelliteData[satelliteId];

  useEffect(() => {
    setSatelliteId(params.satelliteId);
  }, [params.satelliteId, setSatelliteId]);

  useEffect(() => {
    setBeamId(params.beamId || 'summary_eirp');
  }, [params.beamId, setBeamId]);

  return <Fragment>{satellite && <SatelliteInfoCard beamId={beamId} satellite={satellite} />}</Fragment>;
};

export default SatellitePage;
