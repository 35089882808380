import React, { Fragment, ReactElement, useState } from 'react';

import { SatelliteDetail, SatelliteDownloads } from '../../data/models/Satellite';
import Downloads from './Downloads';

interface Props {
  details: SatelliteDetail[];
  downloads: SatelliteDownloads[];
}

export default function SatelliteDetails({ details, downloads }: Props): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <button className="bg-accent flex w-full p-3 font-bold text-white" onClick={() => setOpen(o => !o)}>
        <i className="">
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            {open ? (
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            ) : (
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            )}
          </svg>
        </i>
        View details
      </button>

      {open && (
        <div className="divide-accent flex-grow h-full p-3 overflow-y-auto bg-white divide-y-2">
          <dl className="space-y-3">
            {details.map((detail, idx) => (
              <Detail key={idx} detail={detail} />
            ))}
          </dl>
          {downloads && <Downloads downloads={downloads} />}
        </div>
      )}
    </Fragment>
  );
}

function Detail({ detail }: { detail: SatelliteDetail }): ReactElement {
  return (
    <div>
      <dt className="text-primary-800 text-xs font-bold uppercase">{detail.label}</dt>
      <dd className="text-sm">
        {detail.items.map((item, idx) => (
          <span className="block" key={idx}>
            {item}
          </span>
        ))}
      </dd>
    </div>
  );
}
