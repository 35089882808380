import React from 'react';
import { useHistory } from 'react-router-dom';

import { Satellite } from '../../data/models/Satellite';

interface BeamSelectorProps {
  satellite: Satellite;
  beamId: string;
}

const BeamSelector: React.FC<BeamSelectorProps> = ({ satellite, beamId }) => {
  const { push } = useHistory();
  const beams = satellite.contours;

  return (
    <div>
      <p className="text-xs font-light">View {satellite.name} coverage area:</p>

      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        value={beamId}
        className="form-select w-full"
        onChange={evt => push(`/satellites/${satellite.slug}/${evt.target.value}`)}
      >
        {beams
          .filter(beam => beam.slug !== 'elevation_circles')
          .map(beam => (
            <option key={beam.slug} value={beam.slug}>
              {beam.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default BeamSelector;
