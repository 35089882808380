import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import HumanLongitude from '../Fleet/HumanLongitude';

export interface LookAngleResultsProps {
  location: string;
  locLat: number;
  locLng: number;
  satLng: number;
  trueAzimuth: number;
  elevation: number;
  skew: number;
}

export default function LookAngleResults({
  location,
  elevation,
  locLat,
  locLng,
  satLng,
  skew,
  trueAzimuth,
}: LookAngleResultsProps): ReactElement {
  return (
    <div>
      <div className="my-6">
        <GoogleMap
          mapContainerStyle={{ height: 200, width: '100%' }}
          center={{ lat: locLat, lng: locLng }}
          zoom={12}
          options={{
            styles: AppConfig.googleMaps.config.mapStyle as google.maps.MapTypeStyle[],
          }}
        >
          <Marker position={{ lat: locLat, lng: locLng }} title={location} />
        </GoogleMap>
      </div>
      <dl className="space-y-2">
        <LookAngleResult label="Location" value={location} />
        <LookAngleResult label="Location latitude" value={locLat} />
        <LookAngleResult label="Location longitude" value={<HumanLongitude lng={locLng} />} />
        <LookAngleResult label="Satellite longitude" value={<HumanLongitude lng={satLng} />} />
        <LookAngleResult label="True azimuth" value={trueAzimuth} />
        <LookAngleResult
          label="Elevation"
          value={elevation > 0 ? elevation : <div className="text-red-800">No line of sight</div>}
        />
        <LookAngleResult label="Skew" value={skew} />
      </dl>
    </div>
  );
}

interface LookAngleResultProps {
  label: string;
  value: string | number | ReactElement;
}
function LookAngleResult({ label, value }: LookAngleResultProps): ReactElement {
  return (
    <div className="flex items-center justify-between">
      <dt className="mr-3 text-xs font-bold uppercase">{label}</dt>
      <dd>{value || 'N/A'}</dd>
    </div>
  );
}
