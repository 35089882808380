import GoogleMapsConfig from './google-maps.config';

export const AppConfig = {
  telesatSiteUrl: process.env.REACT_APP_TELESAT_HOME,
  data: {
    root: `${process.env.REACT_APP_DATA_URL}`,
    shadowPlot: `${process.env.REACT_APP_DATA_URL}/shadow-plot`,
  },
  googleMaps: GoogleMapsConfig,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
};
