import { Satellites } from './models/Satellite';
import { Teleport } from './models/Teleport';
import satellites from './satellites.json';
import teleports from './teleports.json';

const orderedSatellites: Satellites = {};

Object.keys(satellites.data)
  .sort()
  .forEach(key => {
    orderedSatellites[key] = satellites.data[key];
  });

export const SatelliteData = orderedSatellites;
export const TeleportData = teleports as Teleport[];
