import { yupResolver } from '@hookform/resolvers';
import React, { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SatelliteData } from '../../data/data';
import useSatelliteData from '../../hooks/useSatelliteData';
import LookAngle from '../../lib/look-angle';
import LocationInput from '../Forms/LocationInput';
import SatelliteSelect from '../Forms/SatelliteSelect';
import LookAngleResults, { LookAngleResultsProps } from './LookAngleResults';

const LookAngleCalcSchema = Yup.object().shape({
  satellite: Yup.string()
    .oneOf(Object.keys(SatelliteData), 'You must select a satellite')
    .required('You must select a satellite'),
  location: Yup.string().required('You must enter a valid location'),
  lat: Yup.string()
    .notOneOf(['NaN'], 'You must provide a valid latitude by setting your location')
    .required('You must provide a valid latitude by setting your location'),
  lng: Yup.string()
    .notOneOf(['NaN'], 'You must provide a valid longitude by setting your location')
    .required('You must provide a valid longitude by setting your location'),
});

export default function LookAngleCalculator(): ReactElement {
  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(LookAngleCalcSchema),
  });

  const [lookAngleResults, setLookAngleResults] = useState<LookAngleResultsProps>();
  const { satellites, error } = useSatelliteData();

  function onSubmit({ satellite, lat, lng, location }) {
    const locLat = parseFloat(lat);
    const locLng = parseFloat(lng);
    const satLng = satellites?.[satellite].lng;

    const [trueAzimuth, elevation, skew] = LookAngle(locLat, locLng, satLng);

    setLookAngleResults({ location, locLat, locLng, satLng, trueAzimuth, elevation, skew });
  }

  return (
    <div className="lg:grid-cols-2 max-w-7xl grid items-stretch flex-grow w-full mx-auto bg-white">
      <FormProvider {...form}>
        <form className="p-6 overflow-y-auto" onSubmit={form.handleSubmit(onSubmit)}>
          <h1 className="text-lg font-medium leading-6 text-gray-900">Look Angle Calculator</h1>
          {error && <p className="text-red-700">{error}</p>}
          <div className="mt-6 space-y-6">
            <SatelliteSelect />
            <LocationInput />

            <button className="button w-full" type="submit">
              Calculate
            </button>
          </div>
        </form>
      </FormProvider>
      <div className="p-6 overflow-y-auto">
        {lookAngleResults ? (
          <LookAngleResults {...lookAngleResults} />
        ) : (
          <div className="bg-gray-50 flex flex-col items-center justify-center h-full p-6 border-4 border-gray-200 border-dashed">
            <p className="text-2xl font-bold text-gray-300 uppercase">No data</p>
            <p className="text-lg text-gray-300">Use the form to calculate look angle data</p>
          </div>
        )}
      </div>
    </div>
  );
}
