import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import ValidationError from '../Forms/ValidationError';

export enum Mode {
  CNR = 'CNR',
  Angle = 'Angle',
}

export default function ModeRadios(): ReactElement {
  const { errors } = useFormContext();

  return (
    <div>
      <Radio value={Mode.CNR} label="C/N Mode" />
      <Radio value={Mode.Angle} label="FDS Angle Mode" />
      <ValidationError error={errors?.predictionMode} />
    </div>
  );
}

interface RadioProps {
  value: Mode;
  label: string;
}

function Radio({ value, label }: RadioProps): ReactElement {
  const { register } = useFormContext();

  const id = `mode_${value}`;

  return (
    <div className="flex items-center">
      <input type="radio" name="mode" id={id} value={value} ref={register} />
      <label className="label block ml-2" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
