import { LoadScript } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AppConfig } from './config/app.config';
import CenterOfBoxPage from './pages/center-of-box';
import LookAngleCalculatorPage from './pages/look-angle-calculator';
import SatellitesPage from './pages/satellites';
import SunTransitCalculatorPage from './pages/sun-transit-calculator';
import PWAPrompt from 'react-ios-pwa-prompt';

import ReactGA from 'react-ga';
import useOnlineStatus from './hooks/useOnlineStatus';

function App(): React.ReactElement {
  const { listen } = useHistory();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    ReactGA.initialize(AppConfig.googleAnalytics);

    listen(listener => {
      ReactGA.pageview(listener.pathname);
    });
  }, []);

  return (
    <HelmetProvider>
      <LoadScript googleMapsApiKey={AppConfig.googleMaps.apiKey} libraries={['places']} loadingElement={<></>}>
        <main>
          {!isOnline && (
            <p className="flex px-3 py-1 text-xs text-white bg-red-800">
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                />
              </svg>
              You are offline. Functionality may be limited.
            </p>
          )}
          <Switch>
            <Route path={['/']} exact>
              <Redirect to="/satellites" />
            </Route>
            <Route path={['/satellites']} component={SatellitesPage} />
            <Route path={['/look-angle-calculator']} component={LookAngleCalculatorPage} />
            <Route path={['/centre-of-box']} component={CenterOfBoxPage} />
            <Route path={['/sun-transit-calculator']} component={SunTransitCalculatorPage} />
          </Switch>
        </main>
        <PWAPrompt />
      </LoadScript>
    </HelmetProvider>
  );
}

export default App;
