import React, { ReactElement } from 'react';
import CenterOfBoxData from '../../lib/centre-of-box-data';

interface Props {
  title: string;
  csv?: string;
  error?: string;
}

export default function CenterOfBoxDataTable({ csv, title, error }: Props): ReactElement {
  if (error) {
    return (
      <div className="bg-primary-100 border-primary-400 flex flex-col items-center justify-center flex-grow p-6 border-4 border-dashed">
        <p className="text-primary-500 text-xl" dangerouslySetInnerHTML={{ __html: error }} />
      </div>
    );
  }

  if (!csv) {
    return (
      <div className="bg-gray-50 flex flex-col items-center justify-center flex-grow p-6 border-4 border-gray-200 border-dashed">
        <p className="text-2xl font-bold text-gray-300 uppercase">No data</p>
        <p className="text-lg text-gray-300">Select a satellite to view center of box info</p>
      </div>
    );
  }

  const data = CenterOfBoxData(csv);

  return (
    <table className="font-mono text-xs">
      <thead>
        <tr>
          <th className="text-base text-left" colSpan={3}>
            {title}
          </th>
        </tr>
        <tr>
          <th className="text-left">
            <div>Closest Approach Time UTC</div>
            <div>(Year/Mo/Da Hr:Mn:Se:Ms)</div>
          </th>
          <th className="text-right">
            <div>Lat. Deviation</div>
            <div>(Deg)</div>
          </th>
          <th className="text-right">
            <div>Lon. Deviation</div>
            <div>(Deg)</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.rows.map(row => (
          <tr key={row.closestApproachTime}>
            <td>{row.closestApproachTime}</td>
            <td className="text-right">{row.latDeviation}</td>
            <td className="text-right">{row.lngDeviation}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">Last updated</th>
          <td colSpan={2} className="text-right">
            {data.lastUpdated}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
