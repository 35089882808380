export interface CenterOfBoxData {
  lastUpdated: string;
  rows: CenterOfBoxDataRow[];
}

interface CenterOfBoxDataRow {
  closestApproachTime: string;
  latDeviation: string;
  lngDeviation: string;
}

export default function CenterOfBoxData(csv: string): CenterOfBoxData {
  const values = csv.trim().split(/,|\n/);
  const lastUpdated = values[0];
  const rows: CenterOfBoxDataRow[] = [];

  for (let i = 1; i < values.length; i += 3) {
    const data = {
      closestApproachTime: values[i],
      latDeviation: values[i + 1],
      lngDeviation: values[i + 2],
    };

    rows.push(data);
  }

  return { lastUpdated, rows };
}
