/**
 * Convert radians to degrees
 * @param {number} radians Radians
 * @returns {number} Degrees
 */
function deg(radians: number): number {
  return (radians * 180.0) / Math.PI;
}

export default deg;
