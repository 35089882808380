import React from 'react';
import { Link } from 'react-router-dom';

import { Satellite } from '../../data/models/Satellite';
import BeamSelector from './BeamSelector';
import HumanLongitude from './HumanLongitude';
import SatelliteDetails from './SatelliteDetails';

interface SatelliteInfoCardProps {
  satellite: Satellite;
  beamId: string;
}

const SatelliteInfoCard: React.FC<SatelliteInfoCardProps> = ({ satellite, beamId }) => {
  return (
    <div className="absolute right-0 flex flex-col w-full max-w-md max-h-full">
      <div className="card">
        <div className="flex items-center justify-between">
          <div className="flex items-baseline space-x-3">
            <h1 className="text-primary-800 font-bold">{satellite.name}</h1>
            <HumanLongitude lng={satellite.lng} />
            {satellite.subtitle && <span className="text-xs">{satellite.subtitle}</span>}
          </div>
          <Link to="/satellites" className="w-8 h-8">
            <svg fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </div>
        <BeamSelector beamId={beamId} satellite={satellite} />
      </div>
      {satellite.details && <SatelliteDetails details={satellite.details} downloads={satellite.downloads} />}
    </div>
  );
};

export default SatelliteInfoCard;
