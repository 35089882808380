import { Marker, useGoogleMap } from '@react-google-maps/api';
import React, { ReactElement, useEffect } from 'react';

import { Station } from '../../data/models/Station';
import { AppConfig } from '../../config/app.config';

interface Props {
  station: Station;
}

export default function StationMarker({ station }: Props): ReactElement {
  const map = useGoogleMap();

  useEffect(() => {
    map.panTo({ lat: station.Latitude, lng: station.Longitude });
  }, [station, map]);

  return (
    station?.Latitude &&
    station?.Longitude && (
      <Marker
        position={{ lat: station.Latitude, lng: station.Longitude }}
        title={station.LocationFormattedAddress}
        icon={{ ...AppConfig.googleMaps.config.teleportMarker.icon }}
      />
    )
  );
}
