import React, { ReactElement } from 'react';

import { Satellite } from '../../data/models/Satellite';
import { Station } from '../../data/models/Station';

interface Props {
  station: Station;
  satellite: Satellite;
}

export default function TransitSummary({ satellite, station }: Props): ReactElement {
  return (
    <dl>
      <SummaryItem title="Location" definition={station?.LocationFormattedAddress} />
      <SummaryItem title="Satellite" definition={satellite?.name} />
      <SummaryItem title="Satellite longitude (E)" definition={satellite?.lng} />
      <SummaryItem title="Station longitude (E)" definition={station?.Longitude} />
      <SummaryItem title="Station latitude (N)" definition={station?.Latitude} />
      <SummaryItem title="Antenna Size (m)" definition={isNaN(station?.Diameter) ? 'N/A' : station.Diameter} />
      <SummaryItem
        title="System temperature (K)"
        definition={isNaN(station?.SystemTemp) ? 'N/A' : station.SystemTemp}
      />
      <SummaryItem title="Solar Flux Index at 2800 MHz (SFU)" definition={station?.SolarFlux} />
    </dl>
  );
}

interface SummaryItemProps {
  title: ReactElement | string | number;
  definition: ReactElement | string | number;
}

export function SummaryItem({ title, definition }: SummaryItemProps): ReactElement {
  return (
    <div className="flex items-center justify-between">
      <dt className="font-bold">{title}</dt>
      <dd className="ml-3">{definition}</dd>
    </div>
  );
}
