import React, { ReactElement, useState } from 'react';

import Logo from '../assets/telesat-logo-white.png';
import NavItem from './NavItem';
import { AppConfig } from '../config/app.config';

export default function Header(): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-primary-700 border-b-2 border-white">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-16">
          <div className="lg:flex items-center flex-shrink-0 hidden ml-3">
            <a href={AppConfig.telesatSiteUrl}>
              <img className="max-h-6 block w-auto h-auto" src={Logo} alt="Telesat" />
            </a>
          </div>
          <div className="lg:ml-auto flex items-center">
            <a
              className="hover:text-white hover:opacity-75 flex items-center mr-12 font-bold text-white"
              href={AppConfig.telesatSiteUrl}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Telesat website</span>
            </a>
          </div>
          <div className="relative">
            <div className=" flex items-center">
              <button
                className="hover:text-white hover:opacity-75 flex items-center mr-3 font-bold text-white"
                aria-label="Main menu"
                aria-expanded="false"
                onClick={() => setOpen(o => !o)}
              >
                <svg className="block w-10 h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {open ? <path d="M6 18L18 6M6 6l12 12" /> : <path d="M4 6h16M4 12h16M4 18h16" />}
                </svg>
              </button>
            </div>
            {open && (
              <div className="sm:px-0 absolute right-0 w-screen max-w-md mt-3">
                <div className="sm:gap-8 sm:p-8 relative z-20 grid gap-6 px-5 py-6 bg-white">
                  <NavItem to="/satellites" label="Our Fleet" info="Explore our interactive satellite map" />
                  <NavItem
                    to="/look-angle-calculator"
                    label="Look Angle Calculator"
                    info="Get look angle results for your location"
                  />
                  <NavItem
                    to="/sun-transit-calculator"
                    label="Sun Transit Calculator"
                    info="Compute intervals where signals may be degraded"
                  />
                  <NavItem
                    to="/centre-of-box"
                    label="Centre of Box"
                    info="Info used to point ground antennas at satellites"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
