import React, { ReactElement } from 'react';

import Header from '../components/Header';
import LookAngleCalculator from '../components/LookAngleCalculator/LookAngleCalculator';

export default function LookAngleCalculatorPage(): ReactElement {
  return (
    <div className="lg:flex lg:min-h-screen lg:flex-col">
      <Header />
      <LookAngleCalculator />
    </div>
  );
}
