import React, { ReactElement } from 'react';

interface Props {
  transitTable;
}

export default function TransitTableFds({ transitTable }: Props): ReactElement {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Date</span>
              <span>Year/Mn/Dy</span>
            </div>
          </th>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Enters</span>
              <span>5 Degree</span>
              <span>Hr:Mn:Sc</span>
            </div>
          </th>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Enters</span>
              <span>1 Degree</span>
              <span>Hr:Mn:Sc</span>
            </div>
          </th>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Leaves</span>
              <span>1 Degree</span>
              <span>Hr:Mn:Sc</span>
            </div>
          </th>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Leaves</span>
              <span>5 Degree</span>
              <span>Hr:Mn:Sc</span>
            </div>
          </th>
          <th>
            <div className="flex flex-col justify-between h-full">
              <span>Duration</span>
              <span>min</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {transitTable?.map((item, idx) => (
          <tr key={idx}>
            <td>{item[0]?.T1.toDate()}</td>
            <td>{item[0]?.T1.toTod()}</td>
            <td>{item[1]?.T1.toTod()}</td>
            <td>{item[1]?.T2.toTod()}</td>
            <td>{item[0]?.T2.toTod()}</td>
            <td>{((item[0]?.T2.getTime() - item[0].T1.getTime()) / 60000)?.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
