import { Marker } from '@react-google-maps/api';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppConfig } from '../../config/app.config';
import { MapContext } from '../../contexts/MapContext';
import { Satellite } from '../../data/models/Satellite';
import clearMap from '../../utils/clear-map';
import loadBeams from '../../utils/load-beams';
import HumanLongitude from './HumanLongitude';

interface SatelliteSelectorProps {
  satellites: Satellite[];
}

const SatelliteSelector: React.FC<SatelliteSelectorProps> = ({ satellites }) => {
  const { map } = useContext(MapContext);
  const { push } = useHistory();
  const usedLngs = [];

  const [activeSatellite, setActiveSatellite] = useState<Satellite>();

  useEffect(() => {
    clearMap(map);
    if (activeSatellite?.slug) {
      loadBeams(activeSatellite.slug, 'summary_eirp', map);
    }
  }, [activeSatellite, map]);

  return (
    <Fragment>
      {activeSatellite && (
        <div className="card absolute right-0">
          <div className="flex items-center justify-between">
            <div className="flex items-baseline space-x-3">
              <h1 className="font-bold">{activeSatellite?.name}</h1>
              <HumanLongitude lng={activeSatellite?.lng} />
              {activeSatellite.subtitle && <span className="text-xs">{activeSatellite.subtitle}</span>}
            </div>
          </div>
        </div>
      )}

      {satellites?.map(satellite => {
        const lng = Math.ceil(satellite.lng / 10) * 10;

        const lat = (usedLngs.filter(usedLng => Math.abs(usedLng - lng) < 5)?.length || 0) * -15;
        usedLngs.push(lng);

        return (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <Marker
            icon={{ ...AppConfig.googleMaps.config.satelliteMarker.icon, anchor: new google.maps.Point(25, 25) }}
            key={satellite.slug}
            position={{ lat, lng }}
            onClick={() => push(`/satellites/${satellite.slug}`)}
            onMouseOver={() => setActiveSatellite(satellite)}
            onMouseOut={() => setActiveSatellite(null)}
          />
        );
      })}
    </Fragment>
  );
};

export default SatelliteSelector;
