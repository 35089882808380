import { Marker } from '@react-google-maps/api';
import React, { Fragment, useState } from 'react';
import { AppConfig } from '../../config/app.config';
import { Teleport, TeleportType } from '../../data/models/Teleport';
import teleports from '../../data/teleports.json';

const Teleports: React.FC = () => {
  const [active, setActive] = useState<Teleport>();

  return (
    <Fragment>
      {active && (
        <div className="card absolute right-0">
          <dl>
            <dd className="font-bold">{active?.label}</dd>
            <dt className="text-xs">
              <span>Teleport</span> {active?.type === TeleportType.ThirdParty && <span>(3rd party)</span>}
            </dt>
          </dl>
        </div>
      )}

      {teleports.map((teleport: Teleport) => {
        let icon;
        switch (teleport.type) {
          case TeleportType.ThirdParty: {
            icon = AppConfig.googleMaps.config.teleportMarker3rdParty.icon;
            break;
          }
          default: {
            icon = AppConfig.googleMaps.config.teleportMarker.icon;
            break;
          }
        }

        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        return (
          <Marker
            icon={{ ...icon, anchor: new google.maps.Point(21, 50) }}
            key={teleport.label}
            position={{ lat: teleport.lat, lng: teleport.lng }}
            onClick={() => setActive(teleport)}
            onMouseOver={() => setActive(teleport)}
            onMouseOut={() => setActive(null)}
          />
        );
      })}
    </Fragment>
  );
};

export default Teleports;
