import MapStyle from '../assets/telesat.mapstyle.json';

// TODO: Get directly from Tailwind.config
const colors = {
  primary: {
    50: '#F7F8FB',
    100: '#EBEEF6',
    200: '#CDD4E8',
    300: '#AFBADB',
    400: '#7287BF',
    500: '#3653A4',
    600: '#314B94',
    700: '#203262',
    800: '#18254A',
    900: '#101931',
  },
  accent: '#88674F',
  white: '#fff',
};

const teleportMarker = {
  icon: {
    path:
      'M22.6 12c-1.1 0-2-.9-2-2s.9-2 2-2c3.2 0 6.1 1.3 8.1 3.4 2.1 2.1 3.4 5 3.4 8.2 0 1.1-.9 2-2 2s-2-.9-2-2c0-2.1-.8-4-2.2-5.3-1.4-1.4-3.2-2.3-5.3-2.3zm0-8c4.3 0 8.2 1.7 11 4.6 2.8 2.8 4.6 6.7 4.6 11 0 1.1.9 2 2 2s2-.9 2-2c0-5.4-2.2-10.3-5.7-13.8C32.9 2.2 28 0 22.6 0c-1.1 0-2 .9-2 2s.9 2 2 2zm-15-.8C3 7.2 0 13 0 19.6 0 31.4 9.6 41 21.4 41c6.6 0 12.5-3 16.4-7.6L7.6 3.2zm5.2 39.5c-4.7 0-8.8 3-10.3 7.3h20.7c-1.5-4.3-5.6-7.3-10.4-7.3z',
    strokeWeight: 0,
    fillColor: colors.primary['800'],
    fillOpacity: 1,
    scale: 0.3,
  },
};

const teleportMarker3rdParty = {
  icon: {
    ...teleportMarker.icon,
    fillColor: colors.accent,
  },
};

const GoogleMapsConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  config: {
    mapStyle: MapStyle,
    sunTransit: {
      innerColor: colors.accent,
      outerColor: colors.primary['800'],
      innerLimit: 1.0,
      outerLimit: 5.0,
    },
    beamContour: {
      fillColor: colors.accent,
      fillOpacity: 0.3,
      strokeColor: colors.white,
      strokeWeight: 1,
      strokeOpacity: 0.3,
    },
    beamMarker: {
      icon: {
        path: 'M -15,-10 15,-10 15,10 -15,10 z',
        fillColor: '#fff',
        fillOpacity: 1,
        strokeOpacity: 0,
      },
      label: {
        fontSize: '10px',
        fontFamily: 'verdana',
      },
    },
    elevationCircles: {
      strokeColor: colors.primary['800'],
      strokeWeight: 3,
    },
    satelliteMarker: {
      icon: {
        path:
          'M33.8 10c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6 2.6 0 5 1.1 6.7 2.8 1.7 1.7 2.8 4.1 2.8 6.7 0 .9-.7 1.6-1.6 1.6-.9 0-1.7-.7-1.6-1.6 0-1.7-.7-3.3-1.8-4.4-1.2-1.3-2.8-1.9-4.5-1.9zm0-6.7c3.6 0 6.8 1.4 9.1 3.8 2.3 2.3 3.8 5.5 3.8 9.1 0 .9.7 1.6 1.7 1.6.9 0 1.6-.7 1.6-1.6 0-4.5-1.8-8.5-4.7-11.4-3-3-7-4.8-11.5-4.8-.9 0-1.6.7-1.6 1.6 0 1 .7 1.7 1.6 1.7zm6.9 19.4L27.2 9.3c-.9-.9-2.1-.7-2.7.4 0 0-1.9 3.6-1.9 6.8v.8c.1.8 1 2.2 1.9 3.1l5.2 5.2c.9.9 2.3 1.7 3.1 1.9h.8c3.3 0 6.8-1.9 6.8-1.9 1.1-.7 1.3-2 .3-2.9zm-12.5 4.7l-5.6-5.6c-.8-.8-2.1-.8-3 0L12 29.4c-.8.8-.8 2.1 0 3l5.6 5.6c.8.8 2.1.8 3 0l7.6-7.6c.9-.9.9-2.2 0-3zm3.3 5.7c-.8-.8-2.1-.8-3 0l-5.2 5.2c-.8.8-.8 2.1 0 3l8.1 8.1c.8.8 2.1.8 3 0l5.2-5.2c.8-.8.8-2.1 0-3l-8.1-8.1zm-19.8-6.5l5.2-5.2c.8-.8.8-2.1 0-3l-8.1-8.1c-.8-.8-2.1-.8-3 0L.6 15.5c-.8.8-.8 2.1 0 3l8.1 8.1c.8.9 2.2.9 3 0zm-2.3 6.1c-.6-.6-1.6-.6-2.2 0l-1.1 1.1c-.6.6-.6 1.6 0 2.2l7.9 7.9c.6.6 1.6.6 2.2 0l1.1-1.1c.6-.6.6-1.6 0-2.2l-7.9-7.9z',
        strokeWeight: 0,
        fillColor: colors.primary['800'],
        fillOpacity: 1,
        scale: 0.5,
      },
    },
    teleportMarker,
    teleportMarker3rdParty,
  },
};

export default GoogleMapsConfig;
