import Axios from 'axios';
import { useEffect, useState } from 'react';
import { AppConfig } from '../config/app.config';
import { SatelliteData } from '../data/data';
import { Satellites } from '../data/models/Satellite';
import FleetLongitudesData from '../lib/fleet-longitude-data';

export default function useSatelliteData(): { satellites: Satellites; error: string } {
  const [satLngs, setSatLngs] = useState(SatelliteData);
  const [error, setError] = useState<string>();

  useEffect(() => {
    Axios.get(`${AppConfig.data.root}/FleetLong.csv`)
      .then(res => setSatLngs(FleetLongitudesData(res.data)))
      .catch(() => setError('Unable to load up-to-date fleet data. Using original data.'));
  }, []);

  return { satellites: satLngs, error };
}
