import React, { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SatelliteBand } from '../../data/models/Satellite';
import ValidationError from '../Forms/ValidationError';

interface Props {
  bands: SatelliteBand[];
}

export default function FrequencyBand({ bands }: Props): ReactElement {
  const { register, errors, watch, setValue } = useFormContext();

  const band = watch('band');

  useEffect(() => {
    let freq: number;
    let temp: number;

    switch (band) {
      case SatelliteBand.C: {
        freq = 4;
        temp = 114;
        break;
      }
      case SatelliteBand.Ka: {
        freq = 19.9;
        temp = 235;
        break;
      }
      case SatelliteBand.Ku: {
        freq = 12;
        temp = 158;
        break;
      }
      case SatelliteBand.X: {
        freq = 12;
        temp = 138;
        break;
      }
      case SatelliteBand.DBS: {
        freq = 12.5;
        temp = 163;
        break;
      }
      default: {
        freq = undefined;
        temp = undefined;
      }
    }

    setValue('frequency', freq);
    setValue('temperature', temp);
  }, [band, setValue]);

  return (
    <div>
      <label className="label block" htmlFor="band">
        Frequency Band
      </label>
      <select className="form-select w-full" name="band" ref={register}>
        <option>Select a frequency band</option>
        {bands?.map(band => (
          <option key={band} value={band}>
            {band}
          </option>
        ))}
      </select>
      <input type="hidden" ref={register} name="frequency" />
      <ValidationError error={errors?.band} />
    </div>
  );
}
