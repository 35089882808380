import React, { ReactElement } from 'react';

import { Satellite } from '../../data/models/Satellite';
import { Station } from '../../data/models/Station';
import Downloads from './Downloads';
import TransitSummary from './TransitSummary';
import TransitTableEng from './TransitTableEng';
import TransitTableFds from './TransitTableFds';

interface Props {
  transitTable;
  satellite: Satellite;
  station: Station;
}

export default function TransitDataSummary({ transitTable, satellite, station }: Props): ReactElement {
  return (
    <div className="p-6 overflow-y-auto">
      {transitTable ? (
        <div className=" space-y-3 text-xs">
          <Downloads satellite={satellite} />
          <p>All times are UTC</p>
          <TransitSummary station={station} satellite={satellite} />
          {transitTable?.HaveCNR ? (
            <TransitTableEng transitTable={transitTable} />
          ) : (
            <TransitTableFds transitTable={transitTable} />
          )}
        </div>
      ) : (
        <div className="bg-gray-50 flex flex-col items-center justify-center h-full p-6 border-4 border-gray-200 border-dashed">
          <p className="text-2xl font-bold text-gray-300 uppercase">No data</p>
          <p className="text-lg text-gray-300">Use the form to calculate sun transit data</p>
        </div>
      )}
    </div>
  );
}
