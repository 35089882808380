import { Autocomplete } from '@react-google-maps/api';
import React, { Fragment, ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import geocode from '../../utils/geocode';
import getCurrentPosition from '../../utils/get-current-position';
import ValidationError from './ValidationError';

export default function LocationInput(): ReactElement {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const { register, setValue, errors, getValues } = useFormContext();

  function clearLatLng() {
    setValue('lat', '');
    setValue('lng', '');
  }

  async function onPlaceChanged() {
    clearLatLng();

    let lat: number;
    let lng: number;
    const place = autocomplete.getPlace();

    if (place?.geometry) {
      setValue('location', place.formatted_address, { shouldValidate: true });
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
    } else {
      const latLng = getValues('location').match(/-?\d+\.?\d+/g);

      if (latLng) {
        lat = parseFloat(latLng[0]);
        lng = parseFloat(latLng[1]);
      } else {
        const [{ formatted_address, geometry }] = await geocode(getValues('location'));
        lat = geometry?.location.lat();
        lng = geometry?.location.lng();
        setValue('location', formatted_address, { shouldValidate: true });
      }
    }

    setValue('lat', lat, { shouldValidate: true });
    setValue('lng', lng, { shouldValidate: true });
  }

  async function onGetCurrentLocation() {
    clearLatLng();

    try {
      const {
        coords: { latitude, longitude },
      } = await getCurrentPosition();

      setValue('lat', latitude, { shouldValidate: true });
      setValue('lng', longitude, { shouldValidate: true });
      setValue('location', `${latitude},${longitude}`, { shouldValidate: true });
    } catch (err) {
      return;
    }
  }

  return (
    <Fragment>
      <div className="">
        <label className="label" htmlFor="location">
          Enter a location
        </label>

        <Autocomplete onLoad={acRef => setAutocomplete(acRef)} onPlaceChanged={onPlaceChanged}>
          <div className="flex space-x-1">
            <input
              className="form-input flex-grow w-full"
              type="text"
              name="location"
              ref={register}
              onBlur={() => onPlaceChanged()}
            />
            <button
              type="button"
              className="button bg-accent border-accent flex items-center px-3 text-white rounded-md"
              title="Use my current location"
              onClick={() => onGetCurrentLocation()}
            >
              <svg aria-hidden="true" data-prefix="fas" data-icon="location" className="w-6 h-6" viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z"
                />
              </svg>
            </button>
          </div>
        </Autocomplete>
        <div className="flex flex-wrap items-end justify-between mt-2">
          <div className="flex items-center">
            <label className="text-accent text-xs font-bold uppercase" htmlFor="lat">
              Latitude:
            </label>
            <input
              className="h-auto p-0 ml-2 text-sm bg-transparent"
              disabled
              type="readonly"
              name="lat"
              ref={register}
            />
          </div>
          <div className="flex items-center">
            <label className="text-accent text-xs font-bold uppercase" htmlFor="lng">
              Longitude:
            </label>
            <input
              className="w-auto h-auto p-0 ml-2 text-sm bg-transparent"
              disabled
              type="readonly"
              name="lng"
              ref={register}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <ValidationError error={errors?.lat} />
          <ValidationError error={errors?.lng} />
        </div>
      </div>
    </Fragment>
  );
}
