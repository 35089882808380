import { Contour } from './Contour';

export interface Satellite {
  name: string;
  subtitle?: string;
  slug: string;
  lng: number;
  bands: SatelliteBand[];
  details: SatelliteDetail[];
  contours: Contour[];
  downloads?: SatelliteDownloads[];
}

export enum SatelliteBand {
  C = 'C',
  Ka = 'Ka',
  Ku = 'Ku',
  X = 'X',
  DBS = 'DBS',
}

export interface SatelliteDetail {
  label: string;
  items: string[];
}

export interface SatelliteDownloads {
  label: string;
  url: string;
}

export interface Satellites {
  [id: string]: Satellite;
}
