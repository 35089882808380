import { Polygon } from '@react-google-maps/api';
import React, { ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import { Satellite } from '../../data/models/Satellite';

interface Props {
  satellite: Satellite;
}

export default function Footprint({ satellite }: Props): ReactElement {
  const footprint = footPrint(satellite.lng).map(i => new google.maps.LatLng(i.lat, i.long));

  return (
    footprint?.length && (
      <Polygon paths={footprint} options={{ ...AppConfig.googleMaps.config.elevationCircles, fillOpacity: 0 }} />
    )
  );
}
