import { AppConfig } from '../config/app.config';

export function loadBeams(
  satelliteId: string,
  beamId: string,
  map: google.maps.Map,
): Promise<google.maps.Data.Feature[]> {
  return new Promise(resolve => {
    map.data.loadGeoJson(`/static/satellites/${satelliteId}/${beamId}.json`, undefined, feats => {
      for (const feature of feats) {
        map.data.overrideStyle(feature, {
          ...AppConfig.googleMaps.config.beamContour,
          icon: { path: google.maps.SymbolPath.CIRCLE, strokeColor: 'transparent' },
        });
      }

      resolve(feats);
    });
  });
}

export default loadBeams;
