/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="../../../public/static/lib/epoch.js" />
/// <reference path="../../../public/static/lib/shadow.js" />
/// <reference path="../../../public/static/lib/sun.js" />
/// <reference path="../../../public/static/lib/transit.js" />
import { yupResolver } from '@hookform/resolvers';
import React, { Fragment, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { AppConfig } from '../../config/app.config';
import { SatelliteData } from '../../data/data';
import { SatelliteBand } from '../../data/models/Satellite';
import useSatelliteData from '../../hooks/useSatelliteData';
import { SunTransitState } from '../../pages/sun-transit-calculator';
import LocationInput from '../Forms/LocationInput';
import SatelliteSelect from '../Forms/SatelliteSelect';
import ValidationError from '../Forms/ValidationError';
import FrequencyBand from './FrequencyBand';
import ModeRadios, { Mode } from './ModeRadios';
import PredictionSeasonSelect, { PredictionSeason } from './PredictionSeasonSelect';
import PredictionYearSelect from './PredictionYearSelect';

const SunTransitCalcSchema = Yup.object().shape({
  satellite: Yup.string()
    .oneOf(Object.keys(SatelliteData), 'You must select a satellite')
    .required('You must select a satellite'),
  location: Yup.string().required('You must enter a valid location'),
  lat: Yup.string()
    .notOneOf(['NaN'], 'You must provide a valid latitude by setting your location')
    .required('You must provide a valid latitude by setting your location'),
  lng: Yup.string()
    .notOneOf(['NaN'], 'You must provide a valid longitude by setting your location')
    .required('You must provide a valid longitude by setting your location'),
  mode: Yup.string().oneOf(Object.keys(Mode)).required(),
  band: Yup.mixed().when('mode', { is: Mode.CNR, then: Yup.string().oneOf(Object.keys(SatelliteBand)).required() }),
  antenna: Yup.mixed().when('mode', {
    is: Mode.CNR,
    then: Yup.number().typeError('Antenna diameter must be a number').min(0.1).max(50).required(),
  }),
  temperature: Yup.mixed().when('mode', {
    is: Mode.CNR,
    then: Yup.number().typeError('System temperature diameter must be a number').required(),
  }),
});

interface Props {
  setState: React.Dispatch<React.SetStateAction<SunTransitState>>;
}

export default function SunTransitForm({ setState }: Props): ReactElement {
  const { satellites, error } = useSatelliteData();

  const form = useForm({
    defaultValues: {
      satellite: undefined,
      location: undefined,
      lat: undefined,
      lng: undefined,
      predictionYear: undefined,
      predictionSeason: undefined,
      mode: Mode.CNR,
      band: undefined,
      antenna: undefined,
      temperature: undefined,
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(SunTransitCalcSchema),
  });
  const showAdditionalOpts = form.watch('mode') === Mode.CNR;
  const satellite = satellites?.[form.watch('satellite') as string];

  function onSubmit(values) {
    const station = {
      LocationFormattedAddress: values.location,
      Band: values.band,
      Frequency: parseFloat(values.frequency),
      Diameter: parseFloat(values.antenna),
      SystemTemp: parseFloat(values.temperature),
      Latitude: parseFloat(values.lat),
      Longitude: parseFloat(values.lng),
      ComputeCNR: values.mode === Mode.CNR,
      ComputeDuration: values.mode !== Mode.CNR,
      LookupSolarFlux: true,
      SolarFlux: 100.7,
      CNRThreshold: 1.5,
    };

    const d = new Date();
    d.setFullYear(values.predictionYear, values.predictionSeason === PredictionSeason.Spring ? 3 : 9);

    const startmjd = getEquinoxTime(d)['getMjd']() - 90;
    const endmjd = startmjd + 180;
    const transitTable = sunTransit(
      startmjd,
      endmjd,
      satellite.lng,
      station,
      AppConfig.googleMaps.config.sunTransit.innerLimit,
      AppConfig.googleMaps.config.sunTransit.outerLimit,
    );

    setState({ station, satellite, transitTable });
  }

  return (
    <FormProvider {...form}>
      <form className="p-6 overflow-y-auto" onSubmit={form.handleSubmit(onSubmit)}>
        <h1 className="text-lg font-medium leading-6 text-gray-900">Sun Transit Calculator</h1>
        {error && <p className="text-red-700">{error}</p>}
        <div className="mt-6 space-y-6">
          <SatelliteSelect />
          <LocationInput />
          <PredictionYearSelect />
          <PredictionSeasonSelect />
          <ModeRadios />
          {showAdditionalOpts && (
            <Fragment>
              <FrequencyBand bands={satellite?.bands} />

              <div>
                <label className="label" htmlFor="antenna">
                  Antenna diameter (m)
                </label>
                <input
                  className="form-input block w-full pr-10"
                  type="number"
                  name="antenna"
                  step="any"
                  ref={form.register}
                />
                <ValidationError error={form.errors?.antenna} />
              </div>
              <div>
                <label className="label" htmlFor="temperature">
                  System temperature (K)
                </label>
                <input className="form-input block w-full pr-10" type="number" name="temperature" ref={form.register} />
                <ValidationError error={form.errors?.temperature} />
              </div>
            </Fragment>
          )}
          <button className="button w-full" type="submit">
            Calculate
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
