import React, { ReactElement } from 'react';
import { AppConfig } from '../../config/app.config';
import { SatelliteDownloads } from '../../data/models/Satellite';

interface Props {
  downloads: SatelliteDownloads[];
}

export default function Downloads({ downloads }: Props): ReactElement {
  return (
    <section className="py-2 my-2">
      <h1 className="text-accent text-xs font-bold uppercase">Downloads</h1>
      {downloads.map((download, idx) => (
        <a
          key={idx}
          href={`${AppConfig.telesatSiteUrl}/${download.url}`}
          download="test.pdf"
          className="flex py-3 text-sm"
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
              clipRule="evenodd"
            />
          </svg>
          {download.label}
        </a>
      ))}
    </section>
  );
}
