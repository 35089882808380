import React from 'react';

interface HumanLongitude {
  lng: number;
}

const HumanLongitude: React.FC<HumanLongitude> = ({ lng }) => {
  return (
    <span>
      {Math.abs(lng)}° {lng < 0 ? 'WL' : 'EL'}
    </span>
  );
};

export default HumanLongitude;
