import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import ValidationError from '../Forms/ValidationError';

export enum PredictionSeason {
  Spring = 'Spring',
  Fall = 'Fall',
}

export default function PredictionSeasonSelect(): ReactElement {
  const { register, errors } = useFormContext();

  return (
    <div>
      <label className="label block" htmlFor="predictionSeason">
        Prediction season
      </label>
      <select className="form-select w-full" name="predictionSeason" ref={register}>
        <option value={PredictionSeason.Spring}>January to May</option>
        <option value={PredictionSeason.Fall}>August to November</option>
      </select>
      <ValidationError error={errors?.predictionSeason} />
    </div>
  );
}
