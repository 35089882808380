import { GoogleMap } from '@react-google-maps/api';
import React from 'react';
import { Route } from 'react-router-dom';

import SatelliteSelector from '../components/Fleet/SatelliteSelector';
import Teleports from '../components/Fleet/Teleports';
import Header from '../components/Header';
import { AppConfig } from '../config/app.config';
import { MapContextProvider } from '../contexts/MapContext';
import { SatelliteData } from '../data/data';
import SatellitePage from './satellite';

const SatellitesPage: React.FC = () => {
  const satellites = Object.values(SatelliteData);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex-grow w-full h-full">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            styles: AppConfig.googleMaps.config.mapStyle as google.maps.MapTypeStyle[],
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            minZoom: 2,
            maxZoom: 4,
            restriction: {
              latLngBounds: {
                north: 85,
                south: -85,
                west: -180,
                east: 180,
              },
            },
          }}
          zoom={2}
        >
          <MapContextProvider>
            <Teleports />
            <Route path={`/satellites/:satelliteId?/:beamId?`} component={SatellitePage} />
            <Route path={`/satellites`} exact>
              <SatelliteSelector satellites={satellites} />
            </Route>
          </MapContextProvider>
        </GoogleMap>
      </div>
    </div>
  );
};

export default SatellitesPage;
