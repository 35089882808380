import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  to: string;
  label: string;
  info: string;
}

function NavItem({ to, label, info }: NavItemProps): ReactElement {
  return (
    <Link
      to={to}
      className="hover:bg-gray-50 flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg"
    >
      <div className="space-y-1">
        <p className="text-primary-900 text-base font-bold leading-6">{label}</p>
        <p className="text-sm leading-5 text-gray-500">{info}</p>
      </div>
    </Link>
  );
}

export default NavItem;
