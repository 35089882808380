import { Marker, Polyline } from '@react-google-maps/api';
import React, { Fragment, ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import { GenerateShadowTrack } from '../../lib/shadow-track';

interface Props {
  transitTable;
  satelliteLng: number;
}

export default function ShadowTracks({ transitTable, satelliteLng }: Props): ReactElement {
  const shadowtracks = GenerateShadowTrack(
    transitTable,
    satelliteLng,
    AppConfig.googleMaps.config.sunTransit.innerColor,
    AppConfig.googleMaps.config.sunTransit.outerColor,
  );

  return shadowtracks.map((tracks, idx) => (
    <Fragment key={idx}>
      {tracks?.map((track, trackIdx) => (
        <Fragment key={trackIdx}>
          {track.lines?.map((line: google.maps.Polyline, idx) => (
            <Polyline
              key={idx}
              path={line.getPath()}
              options={{ strokeOpacity: 1, strokeWeight: 3, strokeColor: line['strokeColor'] }}
            />
          ))}
          {track.markers?.map((marker: google.maps.Marker, idx) => (
            <Marker
              key={idx}
              icon={{
                ...(marker.getIcon() as google.maps.ReadonlyIcon),
                path: google.maps.SymbolPath.CIRCLE,
                scale: 3,
                strokeWeight: 0,
                fillOpacity: 1,
                rotation: -90,
              }}
              position={marker.getPosition()}
              title={marker.getTitle()}
            />
          ))}
        </Fragment>
      ))}
    </Fragment>
  ));
}
