import { Polygon } from '@react-google-maps/api';
import React, { ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import { Satellite } from '../../data/models/Satellite';
import { Station } from '../../data/models/Station';

interface Props {
  transitTable;
  station: Station;
  satellite: Satellite;
}

export default function InnerCone({ transitTable, station, satellite }: Props): ReactElement {
  const innercone = shadowFootPrint(station.Latitude, station.Longitude, satellite.lng, transitTable['innerLimit']).map(
    i => new google.maps.LatLng(i.lat, i.long),
  );

  return (
    innercone?.length && (
      <Polygon paths={innercone} options={{ ...AppConfig.googleMaps.config.elevationCircles, fillOpacity: 0 }} />
    )
  );
}
