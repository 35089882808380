import { Polygon } from '@react-google-maps/api';
import React, { ReactElement } from 'react';

import { AppConfig } from '../../config/app.config';
import { Satellite } from '../../data/models/Satellite';
import { Station } from '../../data/models/Station';

interface Props {
  station: Station;
  satellite: Satellite;
}

export default function OuterCone({ station, satellite }: Props): ReactElement {
  const outercone = shadowFootPrint(
    station.Latitude,
    station.Longitude,
    satellite.lng,
    AppConfig.googleMaps.config.sunTransit.outerLimit,
  ).map(i => new google.maps.LatLng(i.lat, i.long));

  return (
    outercone?.length && (
      <Polygon paths={outercone} options={{ ...AppConfig.googleMaps.config.elevationCircles, fillOpacity: 0 }} />
    )
  );
}
