/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function ShadowTrack(
  startmjd: number,
  endmjd: number,
  satlong: number,
  width: number,
  color: string,
): { lines: google.maps.Polyline[]; markers: google.maps.Marker[] } {
  //  Predict lat/long coorinates of the satellite shadow as it
  //  crosses the earth (in interval startmjd to endmjd).
  //  Returns an array of tracks (as Polylines) on for each
  //  day in the interval.
  let Satellite: number[];
  let Sun: number[];
  let mjd: number;
  const track: google.maps.Polyline[] = [];
  const markers: google.maps.Marker[] = [];

  satlong = mod360(satlong); // Satellite longitude +ve degE [0-360]

  const RE = C_REARTH;
  const thetaMax = asind(RE / C_RSYNCH) + width / 2;
  const startday = trunc(startmjd);
  const endday = trunc(endmjd + 1);
  const daycount = endday - startday;

  for (let day = 0; day < daycount; day++) {
    track[day] = new google.maps.Polyline({ strokeColor: color });
    let markMade = false;

    for (let sec = 0; sec < C_SECSPERDAY; sec = sec + 10) {
      mjd = startday + day + sec / C_SECSPERDAY;

      if (mjd < startmjd) {
        continue;
      }

      if (mjd > endmjd) {
        break;
      }

      Satellite = getSatellite(mjd, satlong);
      Sun = sunPosition(mjd);

      let SatBodyS = vecSub(Sun, Satellite);
      SatBodyS = vecMul(1.0 / mag(SatBodyS), SatBodyS);

      // const satellitelong = atan2d(Satellite[1], Satellite[0]);
      // const sunlong = atan2d(Sun[1], Sun[0]);
      const theta = acosd(dot(SatBodyS, Satellite) / C_RSYNCH);

      if (theta < thetaMax) {
        const alpha = asind((C_RSYNCH * sind(theta)) / RE) - theta;
        let rshad = Math.sqrt(Math.pow(C_RSYNCH, 2) + Math.pow(RE, 2) - 2.0 * C_RSYNCH * RE * cosd(alpha));

        if (theta > asind(RE / C_RSYNCH)) {
          rshad = Math.sqrt(Math.pow(C_RSYNCH, 2) - Math.pow(RE, 2));
        }

        const shadow = vecMul(rshad, SatBodyS);
        const shadowECF = vecSub(Satellite, shadow);
        const shadowLat = asind(shadowECF[2] / mag(shadowECF));
        const shadowLong = mod360(atan2d(shadowECF[1], shadowECF[0]) - gha(mjd));
        const coord = new google.maps.LatLng(shadowLat, shadowLong);
        track[day].getPath().push(coord);

        //  place a circle at the start of each track with the
        //  starting epoch as a title
        if (!markMade) {
          const d = new Date();

          d.setMjd(mjd);

          const marker = new google.maps.Marker({
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: color,
            },
            position: coord,
            title: d.toEpoch(),
          });

          markers.push(marker);
          markMade = true;
        }
      }
    }
  }

  return { lines: track, markers };
}

export function GenerateShadowTrack(transitTable, satellitelong: number, innerColor: string, outerColor: string) {
  const satLng = mod360(satellitelong);
  let shadowtracks;

  if (transitTable['HaveCNR']) {
    shadowtracks = transitTable.map(item => {
      if (typeof item['cnr'] !== 'undefined') {
        const startmjd1 = item[0]['T1'].getMjd();
        const endmjd1 = item['cnr'].start.getMjd();
        const track1 = ShadowTrack(startmjd1, endmjd1, satLng, 5, outerColor);

        const startmjd2 = item['cnr'].start.getMjd();
        const endmjd2 = item['cnr'].end.getMjd();
        const track2 = ShadowTrack(startmjd2, endmjd2, satLng, 5, innerColor);

        const startmjd3 = item['cnr'].end.getMjd();
        const endmjd3 = item[0]['T2'].getMjd();
        const track3 = ShadowTrack(startmjd3, endmjd3, satLng, 5, outerColor);

        return [track1, track2, track3];
      } else {
        const startmjd1 = item[0]['T1'].getMjd();
        const endmjd1 = item[0]['T2'].getMjd();
        return [ShadowTrack(startmjd1, endmjd1, satLng, 5, outerColor)];
      }
    });
  } else {
    shadowtracks = transitTable.map(item => {
      if (item.length > 1) {
        const startmjd1 = item[0]['T1'].getMjd();
        const endmjd1 = item[1]['T1'].getMjd();
        const track1 = ShadowTrack(startmjd1, endmjd1, satLng, 5, outerColor);

        const startmjd2 = item[1]['T1'].getMjd();
        const endmjd2 = item[1]['T2'].getMjd();
        const track2 = ShadowTrack(startmjd2, endmjd2, satLng, 5, innerColor);

        const startmjd3 = item[1]['T2'].getMjd();
        const endmjd3 = item[0]['T2'].getMjd();
        const track3 = ShadowTrack(startmjd3, endmjd3, satLng, 5, outerColor);

        return [track1, track2, track3];
      } else {
        const startmjd1 = item[0]['T1'].getMjd();
        const endmjd1 = item[0]['T2'].getMjd();
        return [ShadowTrack(startmjd1, endmjd1, satLng, 5, outerColor)];
      }
    });
  }

  return shadowtracks;
}
