import deg from '../utils/deg';
import rad from '../utils/rad';

function LookAngle(locLat: number, locLng: number, satLng: number): [number, number, number] {
  locLat = parseFloat(locLat.toFixed(3));
  locLng = parseFloat((locLng < 0 ? locLng * -1 : (locLng - 180) * -1 + 180).toFixed(3));
  satLng = satLng < 0 ? Math.abs(satLng) : Math.abs(satLng - 360);

  const C_RSYNCH = 42164.17;
  const C_REARTH = 6378.139;

  const StnGeocentric: number[] = [];
  const StnRotated: number[] = [];
  const Satellite: number[] = [];
  const Range: number[] = [];
  const NVE: number[] = [];

  const cosla = Math.cos(rad(locLat));
  const sinla = Math.sin(rad(locLat));
  const coslo = Math.cos(rad(locLng * -1));
  const sinlo = Math.sin(rad(locLng * -1));

  StnGeocentric[0] = C_REARTH * cosla * coslo;
  StnGeocentric[1] = C_REARTH * cosla * sinlo;
  StnGeocentric[2] = C_REARTH * sinla;

  StnRotated[0] = Math.sqrt(Math.pow(StnGeocentric[0], 2.0) + Math.pow(StnGeocentric[1], 2.0));
  StnRotated[1] = 0.0;
  StnRotated[2] = StnGeocentric[2];

  Satellite[0] = C_RSYNCH * Math.cos(rad(locLng - satLng));
  Satellite[1] = C_RSYNCH * Math.sin(rad(locLng - satLng));
  Satellite[2] = 0.0;

  for (let i = 0; i < 3; i++) {
    Range[i] = Satellite[i] - StnRotated[i];
  }

  NVE[0] = Range[1];
  NVE[1] = -Range[0] * sinla + Range[2] * cosla;
  NVE[2] = Range[0] * cosla + Range[2] * sinla;

  const magRange = Math.sqrt(Math.pow(Range[0], 2.0) + Math.pow(Range[1], 2.0) + Math.pow(Range[2], 2.0));

  const trueAzimuth = (180 + deg(Math.atan2(-NVE[0], -NVE[1]))).toFixed(2);
  const elevation = deg(Math.asin(NVE[2] / magRange)).toFixed(2);

  // Skew?
  let skewVal = deg(Math.atan(Math.sin(rad(satLng - locLng)) * Math.tan(rad(90 - locLat))));
  if (skewVal > 180) {
    skewVal = -(360 - skewVal);
  } else if (skewVal < -180) {
    skewVal = 360 + skewVal;
  }

  const skew = skewVal.toFixed(2);

  return [parseFloat(trueAzimuth), parseFloat(elevation), parseFloat(skew)];
}

export default LookAngle;
