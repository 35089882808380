import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

import ValidationError from '../Forms/ValidationError';

export default function PredictionYearSelect(): ReactElement {
  const { register, errors } = useFormContext();

  const currentYear = new Date().getFullYear();
  const yearCount = 3;
  const years = Array.from({ length: yearCount }, (_, idx) => currentYear + idx + 1 - yearCount);

  return (
    <div>
      <label className="label block" htmlFor="predictionYear">
        Prediction year
      </label>
      <select className="form-select w-full" name="predictionYear" ref={register}>
        {years.map(year => (
          <option key={year} selected={year === currentYear}>
            {year}
          </option>
        ))}
      </select>
      <ValidationError error={errors?.predictionYear} />
    </div>
  );
}
